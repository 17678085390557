import {commonEnv} from './environment.common';

export const environment = {
  ...commonEnv,
  production: true,
  sentry: 'https://6303ff17e1cd4294aa6f3774cd031938@sentry.io/1819840',
  version: '0.15.179',
  wunderApiUrl: 'https://api.client.mlink.ortho-team.ch',
  orderApiUrl: 'https://api.order.mlink.ortho-team.ch',
  authenticationApi: 'https://auth.mlink.ortho-team.ch',
  wunderWebUrl: 'https://client.mlink.ortho-team.ch',
  orderWebUrl: 'https://order.mlink.ortho-team.ch',
  formsWebUrl: 'https://forms.ortho-team.ch',
  productNumberSuffix: '',
  auth: {
    clientId: 'ortho-client',
    clientSecret: '0AB0765C-9C08-459D-B536-ADE0105B3D43',
  },
  appDistribution: {
    updateEnabled: true,
    appName: 'Ortho-Client',
    env: 'prod',
    url: 'https://apps.mlink.ortho-team.ch',
  },
  trello: {
    key: '1fc4a74868e4531ac86b3811c9fb6c21',
  },
  settings: {
    newCustomerId: '554007',
    shoeLabelExternalId: '2_1060_1',
    serialShoeMarginLowerBound: 35,
  },
  externalLinks: {
    feedback: 'https://forms.monday.com/forms/a71d5d2042c19563cbf3ebfe2ef920da',
    reportForm: 'https://wkf.ms/3Zi5r2F'
  },
  keycloak: {
    url: 'https://auth.cloud.triarc-labs.com/auth',
    realm: 'ortho-team',
    clientId: 'ortho-team',
    bearerExcludedUrls: [],
    scope: 'openid profile offline_access',
  },
};
